import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CustomSelect as Select } from "../../forms";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { selectStyles } from "../../../styles/select.styles";
import { useEffect, useRef, useState } from "react";
import { texts } from "../../../texts.js";
import useApi from "../../../hooks/useApi";
import { FLOW_ITEMS, GET_FLOW_URL, NEW_GET_COLUMNS_FLOW_URL } from "../../../api/axios";
import { checkIfNodeHasPendingEdges, filterIdFromColumns, setErrorFlowNodes, setInitialFlowEdges, setInitialFlowNodes } from "../utils";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { formatColumns } from "../../fileColumns/utils";
import { TYPE_OPTIONS } from "../../summary/constants";
import BaseModal from "./BaseModal";
import { form_label } from "../../../styles/app-styles";
import useMultiSelect from "../../../hooks/useMultiSelect";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import { getBackendId } from "../../../utils/util";

interface ModifyColumnProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  sourceItemId: string;
  nodeData?: any

}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  updateIsFlowLoading: store.updateIsFlowLoading

});

const ModifyColumn = ({
  open,
  handleClose,
  dataItem,
  id,
  backendId,
  sourceItemId,
  nodeData

}: ModifyColumnProps) => {
  const methodSelectedRef: any = useRef(null);
  const typeSelectedRef: any = useRef(null);
  const formatDateSelectedRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);
  const replaceWithRef: any = useRef(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [inputValueFillGaps, setInputValueFillGaps] = useState("");
  const [inputValueToBeReplaced, setInputValueToBeReplaced] = useState("");
  const [numberOfZeros, setNumberOfZeros] = useState("");
  const [inputValueWouldReplace, setInputValueWouldReplace] = useState("");
  const [removeSpaces, setRemoveSpaces] = useState(false);
  const [mayusMinusValue, setMayusMinusValue] = useState("");
  const [numericValue, setNumericValue] = useState("");
  const [disabledFillGaps, setDisabledFillGaps] = useState(false);
  const [fillEmpyStrings, setFillEmpyStrings] = useState(true);
  const [fillEmptyCellsValue, setFillEmptyCellsValue] = useState("value");
  const [disabledReplaceInput, setDisabledReplaceInput] = useState(false);
  /* const [inputValueNewName, setInputValueNewName] = useState(""); */
  /* const [disabledNewName, setDisabledNewName] = useState(false); */
  const [disabledModifyType, setDisabledModifyType] = useState(false);
  const [disabledMayusMinus, setDisabledMayusMinus] = useState(false);
  const [disabledFillWithZeros, setDisabledFillWithZeros] = useState(false);
  const [disabledNumericValue, setDisabledNumericValue] = useState(false);
  const [totalCellValue, setTotalCellValue] = useState("");
  const [comment, setComment] = useState<any>("");
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;
  /********************************* CARGA DE DATOS ***********************************/

  const {
    data: dataColumns,
    error: errorColumnsFont,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (open) {
      getColumns(
        NEW_GET_COLUMNS_FLOW_URL(
          store.flow.id,
          getBackendId(sourceItemId, store.nodes) as number
        )
      );
      setTimeout(() => {
        setDelayHelper(true);
      }, 1);
    }
  }, [open]);

  const methodOptions = [
    { value: "bfill", label: "Rellenar con valor anterior" },
    { value: "ffill", label: "Rellenar con valor posterior" },
  ];

  const typeOptions = [
    { value: "texto", label: "Texto" },
    { value: "decimal", label: "Número Decimal" },
    { value: "entero", label: "Número Entero" },
    { value: "fecha", label: "Fecha" },
    { value: "boolean", label: "Boolean" },
  ];

  const replaceWithOptions = [
    { value: "valor", label: "Valor" },
    { value: "nulo", label: "Eliminar valor" },
  ];

  const typeFormatDate = [
    { value: "DD/MM/AAAA", label: "DD/MM/AAAA" },
    { value: "DD/MM/AA", label: "DD/MM/AA" },
  ];

  const [methodSelectedOption, setMethodSelectedOption, methodChangeHandler] =
    useSingleSelect();

  const [
    columnsNameSelectedOption,
    setColumnsNameSelectedOption,
    columnsNameChangeHandler,
  ] = useMultiSelect(dataItem? dataItem?.detalle_item?.columnas : undefined);

  const [typeSelectedOption, setTypeSelectedOption, typeChangeHandler] =
    useSingleSelect();

  const [
    formatDateSelectedOption,
    setFormatDateSelectedOption,
    formatDateChangeHandler,
  ] = useSingleSelect();

  const [
    replaceWithSelectedOption,
    setReplaceWithSelectedOption,
    replaceWithChangeHandler,
  ] = useSingleSelect();

  const handleColumnModification = (item: any): void => {
    /*
    el item.value => hace referencia al tipo de modificación ( reemplazar_nombre, rellenar_vacios, reemplazar_exacto, reemplazar_contiene, mayuscula o minuscula, abs o negativos_a_cero, cambiar_tipo_dato )

    label => hace referencia a lo que el usuario modificó anteriormente.
    salvo en la parte de parte de modificar tipo de dato y formato fecha => ahí el back nos manda en el LABEL el value del tipo de formato => por lo que hay que pasarlo en la  parte "label: TYPE_OPTIONS[item.label]" de más abajo usando una constante.
    */
    setColumnsNameSelectedOption(dataItem.detalle_item.columnas);
    switch (item.value) {
      /* case "reemplazar_nombre":
        setInputValueNewName(item.label);
        setDisabledNewName(true);
        break; */
      case "rellenar_vacios":
        if (item.tipo === "valor") {
          setInputValueFillGaps(item.label);
          setFillEmptyCellsValue("value");
        } else {
          setFillEmptyCellsValue("method");
          setMethodSelectedOption({
            label: item.label,
            value: item.label?.endsWith("anterior") ? "bfill" : "ffill",
          });
          setFillEmpyStrings(item.reemplazo_vacios_none);
        }
        setDisabledFillGaps(true);
        break;
      case "reemplazar_exacto":
        setDisabledReplaceInput(true);
        setInputValueToBeReplaced(`${item.label}`);
        setTotalCellValue("totalCellValue");
        if (item.tipo === "nulo") {
          setReplaceWithSelectedOption(replaceWithOptions[1]);
        } else {
          setReplaceWithSelectedOption(replaceWithOptions[0]);
          if (item.tipo === "espacio") {
            setInputValueWouldReplace(" ".repeat(item.cantidad_espacios));
          } else {
            setInputValueWouldReplace(item.label_2);
          }
        }
        break;
      case "reemplazar_contiene":
        setDisabledReplaceInput(true);
        setInputValueToBeReplaced(item.label);
        setTotalCellValue("cellContaining");
        if (item.tipo === "nulo") {
          setReplaceWithSelectedOption(replaceWithOptions[1]);
        } else {
          setReplaceWithSelectedOption(replaceWithOptions[0]);
          if (item.tipo === "espacio") {
            setInputValueWouldReplace(" ".repeat(item.cantidad_espacios));
          } else {
            setInputValueWouldReplace(item.label_2);
          }
        }
        break;
      case "cambiar_tipo_dato":
        setDisabledModifyType(true);
        setTypeSelectedOption({
          value: item.label,
          label: TYPE_OPTIONS[item.label],
        });
        if (item.label === "fecha") {
          setFormatDateSelectedOption({
            value: item.label_2,
            label: item.label_2,
          });
        }
        break;
      case "mayuscula":
      case "minuscula":
        setMayusMinusValue(item.value);
        setDisabledMayusMinus(true);
        break;
      case "abs":
      case "negativos_a_cero":
        setNumericValue(item.value);
        setDisabledNumericValue(true);
        break;
      case "eliminar_espacios":
        setRemoveSpaces(true);
        break;
        case "zfill":
          setDisabledFillWithZeros(true)
          setNumberOfZeros(item.value)
        break;
      default:
        break;
    }
  };

  const handleDataItemChange = (data: any): void => {
    if (data) {
      data.tipo_modificacion?.forEach((item: any) => {
        handleColumnModification(item);
      });
    }
  };

  useEffect(() => {
    if (dataItem && open) {
      handleDataItemChange(dataItem.detalle_item);
      setComment(dataItem.comentario);
 
    }

  }, [dataItem, open]);

  /******************************* POSTEAR ITEM *********************************************/

  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);


  const saveModifyColumn = () => {
    setOpenConfirmationModal(false);
    const completedInfo = {
      tipo: "modificar_columna",
      columnas: columnsNameSelectedOption?.map((column: any) => {
        return column.label;
      }),
      tipo_modificacion: [
        /* inputValueNewName && {
          accion: "reemplazar_nombre",
          valor: `${inputValueNewName}`,
        }, */
        disabledFillGaps && {
          accion: "rellenar_vacios",
          tipo: fillEmptyCellsValue === "value" ? "valor" : "metodo",
          reemplazo_vacios_none: fillEmpyStrings,
          valor:
            fillEmptyCellsValue === "value"
              ? `${inputValueFillGaps}`
              : methodSelectedOption?.value,
        },
        totalCellValue === "cellContaining" &&
          inputValueToBeReplaced && {
            accion: "reemplazar_contiene",
            valor: `${inputValueToBeReplaced}`,
            nuevo_valor:
              replaceWithSelectedOption?.value !== "nulo" &&
              inputValueWouldReplace.trim() !== ""
                ? inputValueWouldReplace
                : undefined,
            tipo:
              replaceWithSelectedOption?.value === "nulo"
                ? "nulo"
                : inputValueWouldReplace.trim() === ""
                ? "espacio"
                : "valor",
            cantidad_espacios:
              replaceWithSelectedOption?.value !== "nulo" &&
              inputValueWouldReplace.trim() === ""
                ? inputValueWouldReplace.length
                : undefined,
          },
        totalCellValue === "totalCellValue" &&
          inputValueToBeReplaced && {
            accion: "reemplazar_exacto",
            valor: `${inputValueToBeReplaced}`,
            nuevo_valor:
              replaceWithSelectedOption?.value !== "nulo" &&
              inputValueWouldReplace.trim() !== ""
                ? inputValueWouldReplace
                : undefined,
            tipo:
              replaceWithSelectedOption?.value === "nulo"
                ? "nulo"
                : inputValueWouldReplace.trim() === ""
                ? "espacio"
                : "valor",
            cantidad_espacios:
              replaceWithSelectedOption?.value !== "nulo" &&
              inputValueWouldReplace.trim() === ""
                ? inputValueWouldReplace.length
                : undefined,
          },
        mayusMinusValue && {
          accion: `${mayusMinusValue}`,
        },
        numericValue && {
          accion: `${numericValue}`,
        },
        removeSpaces && {
          accion: "eliminar_espacios",
        },
        disabledFillWithZeros && {
         accion:"zfill",
         valor: parseInt(numberOfZeros)
        },
        typeSelectedOption?.value === "fecha"
          ? {
              accion: "cambiar_tipo_dato",
              valor: typeSelectedOption?.value,
              nuevo_valor: formatDateSelectedOption?.value,
            }
          : typeSelectedOption?.value != undefined && {
              accion: "cambiar_tipo_dato",
              valor: typeSelectedOption?.value,
            },
      ],
    };
    const resultInfo = {
      calcular: true,
      editar_detalle_item: true,
      parent_ids: [getBackendId(sourceItemId, store.nodes)],
      comentario: comment,
      id: backendId ? backendId : undefined,
      ...getResultInfo(completedInfo),
      ...nodeData.position

    };
    if (dataItem) {
      putModifyColumn(undefined, [resultInfo]);
    } else {
      postModifyColumn(undefined, resultInfo);
    }
    closeAndClean();
  };

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    const completedInfo = {
      columnas: columnsNameSelectedOption?.map((column: any) => {
        return column.label;
      }),
      tipo_modificacion: [
        /* inputValueNewName && {
          accion: "reemplazar_nombre",
          valor: `${inputValueNewName}`,
        }, */
        disabledFillGaps && {
          accion: "rellenar_vacios",
          tipo: fillEmptyCellsValue === "value" ? "valor" : "metodo",
          reemplazo_vacios_none: fillEmpyStrings,
          valor:
            fillEmptyCellsValue === "value"
              ? `${inputValueFillGaps}`
              : methodSelectedOption?.value,
        },
        totalCellValue === "cellContaining" &&
          inputValueToBeReplaced && {
            accion: "reemplazar_contiene",
            valor: `${inputValueToBeReplaced}`,
            nuevo_valor:
              replaceWithSelectedOption?.value !== "nulo" &&
              inputValueWouldReplace.trim() !== ""
                ? inputValueWouldReplace
                : undefined,
            tipo:
              replaceWithSelectedOption?.value === "nulo"
                ? "nulo"
                : inputValueWouldReplace.trim() === ""
                ? "espacio"
                : "valor",
            cantidad_espacios:
              replaceWithSelectedOption?.value !== "nulo" &&
              inputValueWouldReplace.trim() === ""
                ? inputValueWouldReplace.length
                : undefined,
          },
        totalCellValue === "totalCellValue" &&
          inputValueToBeReplaced && {
            accion: "reemplazar_exacto",
            valor: `${inputValueToBeReplaced}`,
            nuevo_valor:
              replaceWithSelectedOption?.value !== "nulo" &&
              inputValueWouldReplace.trim() !== ""
                ? inputValueWouldReplace
                : undefined,
            tipo:
              replaceWithSelectedOption?.value === "nulo"
                ? "nulo"
                : inputValueWouldReplace.trim() === ""
                ? "espacio"
                : "valor",
            cantidad_espacios:
              replaceWithSelectedOption?.value !== "nulo" &&
              inputValueWouldReplace.trim() === ""
                ? inputValueWouldReplace.length
                : undefined,
          },
        mayusMinusValue && {
          accion: `${mayusMinusValue}`,
        },
        numericValue && {
          accion: `${numericValue}`,
        },
        removeSpaces && {
          accion: "eliminar_espacios",
        },
        disabledFillWithZeros && {
          accion:"zfill",
          valor: parseInt(numberOfZeros)
         },
        typeSelectedOption?.value === "fecha"
          ? {
              accion: "cambiar_tipo_dato",
              valor: typeSelectedOption?.value,
              nuevo_valor: formatDateSelectedOption?.value,
            }
          : typeSelectedOption?.value != undefined && {
              accion: "cambiar_tipo_dato",
              valor: typeSelectedOption?.value,
            },
      ],
    };
    store.updateNode(id, {
      ...getResultInfo(completedInfo),
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,      comentario: comment,
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));

  };

  const {
    isLoading: isLoadingPostModifyColumn,
    callApi: postModifyColumn,
    error: errorPostModifyColumn,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutModifyColumn,
    callApi: putModifyColumn,
    error: errorPutModifyColumn,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  function getResultInfo(completedInfo: any) {
    const resultado = {
      columnas: completedInfo.columnas,
      tipo: completedInfo.tipo,
      tipo_modificacion: [],
    };
    const tipoModificacion = completedInfo.tipo_modificacion.filter(
      (estado: any) => estado && estado != "" && estado != false
    );
    if (tipoModificacion.length > 0) {
      resultado.tipo_modificacion = tipoModificacion;
    }
    return resultado;
  }

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );
  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const closeAndClean = () => {
    setDisabledNumericValue(false);
    setNumericValue("");
    setRemoveSpaces(false);
    setInputValueFillGaps("");
    setDisabledFillGaps(false);
    setMethodSelectedOption(undefined);
    setTypeSelectedOption(undefined);
    setFormatDateSelectedOption(undefined);
    setFillEmptyCellsValue("");
    setDisabledModifyType(false);
    setFillEmpyStrings(true);
    /* setInputValueNewName(""); */
    /* setDisabledNewName(false); */
    setTotalCellValue("");
    setInputValueToBeReplaced("");
    setInputValueWouldReplace("");
    setDisabledReplaceInput(false);
    setDisabledMayusMinus(false);
    setMayusMinusValue("");
    setColumnsNameSelectedOption(undefined);
    setReplaceWithSelectedOption(undefined);
    setNumberOfZeros("")
    setDisabledFillWithZeros(false)
    setComment("");
    setDelayHelper(false)
    handleClose();
  };

  /******************************* MANEJO ESTADOS *******************************/

  /* useEffect(() => {
    isColumnNameRepeated(inputValueNewName, dataColumns);
  }, [inputValueNewName]); */

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const handleChangeRemoveSpaces = () => {
    setRemoveSpaces(!removeSpaces);
  };

  const handleChangedisabledFillGaps = () => {
    setInputValueFillGaps("");
    setDisabledFillGaps((prev) => !prev);
  };

  const handleChangedisabledReplaceInput = () => {
    setTotalCellValue("");
    setInputValueToBeReplaced("");
    setInputValueWouldReplace("");
    setDisabledReplaceInput(!disabledReplaceInput);
  };

  const handleChangeDisabledModifyType = () => {
    setTypeSelectedOption(undefined);
    setFormatDateSelectedOption(undefined);
    typeSelectedRef?.current?.clearValue();
    formatDateSelectedRef?.current?.clearValue();
    setDisabledModifyType(!disabledModifyType);
  };

  const handleChangeDisabledFillWithZeros = () => {
    setDisabledFillWithZeros(!disabledFillWithZeros);
    
  };

  /* const handleChangedisabledNewName = () => {
    setInputValueNewName("");
    setDisabledNewName((prev) => !prev);
  }; */

  const handleChangeDisabledMayusMinus = () => {
    setDisabledMayusMinus(!disabledMayusMinus);
    setMayusMinusValue("");
  };

  const handleChangeDisabledNumericValue = () => {
    setDisabledNumericValue(!disabledNumericValue);
    setNumericValue("");
  };

  useEffect(() => {
    if (
      typeSelectedOption?.value === "texto" ||
      typeSelectedOption?.value === "fecha"
    ) {
      setDisabledNumericValue(false);
      setNumericValue("");
    }
    if (
      typeSelectedOption?.value === "decimal" ||
      typeSelectedOption?.value === "entero" ||
      typeSelectedOption?.value === "fecha"
    ) {
      setDisabledMayusMinus(false);
      setMayusMinusValue("");
    }
    if (disabledMayusMinus) {
      setDisabledNumericValue(false);
    }
    if (disabledNumericValue) {
      setDisabledMayusMinus(false);
    }
  }, [typeSelectedOption]);

  
  /******************************* CONTROL DE VALIDACIONES *******************************/

  const formControlValidations = () => {
    if (columnsNameSelectedOption && columnsNameSelectedOption.length !== 0) {
      if (disabledModifyType && !typeSelectedOption) {
        return true;
      }
      if (
        disabledModifyType &&
        typeSelectedOption?.value === "fecha" &&
        !formatDateSelectedOption
      ) {
        return true;
      }
      /* if (
        (disabledNewName && inputValueNewName === "") ||
        isColumnNameRepeated(inputValueNewName, dataColumns)
      ) {
        return true;
      } */
      if (
        (disabledFillGaps &&
          fillEmptyCellsValue === "value" &&
          inputValueFillGaps === "") ||
        (disabledFillGaps &&
          fillEmptyCellsValue === "value" &&
          inputValueFillGaps.trim().length === 0)
      ) {
        return true;
      }
      if (
        disabledFillGaps &&
        fillEmptyCellsValue === "method" &&
        !methodSelectedOption
      ) {
        return true;
      }
      if (disabledReplaceInput) {
        if (replaceWithSelectedOption?.value === "valor") {
          if (
            inputValueWouldReplace === "" ||
            inputValueToBeReplaced === "" ||
            totalCellValue === ""
          ) {
            return true;
          }
        } else {
          return (
            inputValueToBeReplaced === "" ||
            replaceWithSelectedOption === undefined
          );
        }
      }
      if (disabledMayusMinus && mayusMinusValue === "") {
        return true;
      }
      if (disabledFillWithZeros && numberOfZeros === ""){
        return true
      }
      if (disabledNumericValue && numericValue === "") {
        return true;
      }
      if (
        /* !disabledNewName && */
        !disabledFillGaps &&
        !disabledReplaceInput &&
        !disabledMayusMinus &&
        !disabledNumericValue &&
        !removeSpaces &&
        !disabledModifyType && !disabledFillWithZeros
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <BaseModal
      open={open}
      title="Modificar columna"
      cleanAndClose={closeAndClean}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={handleConfirmationModal}
      handleAccept={saveModifyColumn}
      confirmationModalMessage={"Modificar columna"}
      disabledAcceptButton={formControlValidations()}
      width={"100%"}
      hasResultsTable
      source_id={getBackendId(sourceItemId, store.nodes)}
    >
      <SimpleBackdrop open={isLoadingColumns} message={"Cargando datos..."} />
      <Stack
        gap="15px"
        sx={{
          pl: "20px",
          pr: "10px",
          height: "550px",
        }}
      >
        {!dataItem &&  <TextField
          id="comment"
          label={"Comentario"}
          placeholder={"Comentario"}
          size="small"
          multiline
          variant="outlined"
          fullWidth
          value={comment}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setComment(event.target.value);
          }}
          FormHelperTextProps={{
            sx: {
              color: "var(--magenta)",
            },
          }}
          required
        />}
       
        {delayHelper &&  <Stack>
          <Typography sx={form_label}>Elegir Columna</Typography>
          <Select
            isClearable
            onChange={columnsNameChangeHandler}
            reference={columnSelectRef}
            styles={selectStyles(columnsNameSelectedOption)}
            options={formatColumns(filterIdFromColumns(dataColumns))}
            name="columna"
            closeMenuOnSelect={false}
            defaultValue={dataItem ? dataItem?.detalle_item?.columnas : undefined}
            placeholder="Elegir columna..."
            isMulti
          />
        </Stack>}
       {delayHelper &&   <FormControl
          sx={{
            pr: 1,
            width: "100%",
            gap: "15px",
            overflow: "auto",
          }}
        >
          {/* <Stack
            sx={{
              flexDirection: "row",
              alignItems: isColumnNameRepeated(inputValueNewName, dataColumns)
                ? "center"
                : "flex-end",
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label=""
              checked={disabledNewName}
              onChange={handleChangedisabledNewName}
              disabled={
                columnsNameSelectedOption &&
                columnsNameSelectedOption?.length > 1
              }
            />
            <Stack sx={{ width: "100%" }}>
              <Typography sx={form_label}>Nuevo Nombre</Typography>
              <TextField
                id="new_name"
                label={""}
                placeholder={"Nuevo nombre"}
                size="small"
                multiline
                variant="outlined"
                value={inputValueNewName}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValueNewName(event.target.value);
                }}
                error={
                  inputValueNewName === "" ||
                  isColumnNameRepeated(inputValueNewName, dataColumns)
                    ? true
                    : false
                }
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
                disabled={!disabledNewName}
                helperText={
                  isColumnNameRepeated(inputValueNewName, dataColumns)
                    ? "Ya existe una columna con este nombre"
                    : ""
                }
              />
            </Stack>
          </Stack> */}
          <Stack>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                label=""
                checked={disabledFillGaps}
                onChange={handleChangedisabledFillGaps}
              />
              <Stack sx={{ width: "100%" }}>
                <Stack
                  sx={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={form_label}>
                    Rellenar celdas vacías
                  </Typography>
                  <RadioGroup
                    defaultValue=""
                    name="empy-cells-options"
                    sx={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      control={<Radio color="secondary" />}
                      label="Valor"
                      value={"value"}
                      sx={{
                        color: fillEmptyCellsValue === "" ? "red" : "black",
                      }}
                      checked={fillEmptyCellsValue === "value"}
                      disabled={!disabledFillGaps}
                      onChange={(e: any) => {
                        setFillEmptyCellsValue(e.target.value);
                      }}
                    />
                    <FormControlLabel
                      control={<Radio color="secondary" />}
                      label="Valor anterior o posterior"
                      value={"method"}
                      sx={{
                        color: fillEmptyCellsValue === "" ? "red" : "black",
                      }}
                      checked={fillEmptyCellsValue === "method"}
                      disabled={!disabledFillGaps}
                      onChange={(e: any) => {
                        setFillEmptyCellsValue(e.target.value);
                      }}
                    />
                  </RadioGroup>
                </Stack>
                {fillEmptyCellsValue !== "method" ? (
                  <TextField
                    type="number"
                    id="fillGaps"
                    label={""}
                    placeholder={"Rellenar vacíos por..."}
                    size="small"
                    multiline
                    variant="outlined"
                    value={inputValueFillGaps === "" ? "" : inputValueFillGaps}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setInputValueFillGaps(event.target.value);
                    }}
                    error={
                      inputValueFillGaps === "" ||
                      inputValueFillGaps.trim().length === 0
                        ? true
                        : false
                    }
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                    disabled={!disabledFillGaps}
                  />
                ) : (
                  <>
                    <Select
                      isClearable
                      className="cc_select"
                      onChange={methodChangeHandler}
                      reference={methodSelectedRef}
                      styles={selectStyles(methodSelectedOption)}
                      options={methodOptions}
                      closeMenuOnSelect={true}
                      placeholder="Elegir método..."
                      isDisabled={!disabledFillGaps}
                      defaultValue={
                        dataItem
                          ? dataItem.detalle_item.tipo_modificacion?.find(
                              (item: any) => item.value === "rellenar_vacios"
                            )
                            ? {
                                label: dataItem.detalle_item.tipo_modificacion?.find(
                                  (item: any) =>
                                    item.value === "rellenar_vacios"
                                ).label,
                                value: dataItem.detalle_item.tipo_modificacion
                                  ?.find(
                                    (item: any) =>
                                      item.value === "rellenar_vacios"
                                  )
                                  .label?.endsWith("anterior")
                                  ? "bfill"
                                  : "ffill",
                              }
                            : undefined
                          : undefined
                      }
                    />
                  </>
                )}
              </Stack>
            </Stack>
            {fillEmptyCellsValue === "method" && (
              <FormControlLabel
                sx={{ marginLeft: "auto" }}
                control={<Checkbox color="secondary" />}
                label="Rellenar también strings vacios"
                checked={fillEmpyStrings}
                onChange={() => setFillEmpyStrings((prev) => !prev)}
              />
            )}
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label=""
              checked={disabledFillWithZeros}
              onChange={handleChangeDisabledFillWithZeros}
            />
            <Stack
              sx={{
                width: "100%",
              }}
            >
              <Typography sx={form_label}>Rellenar con ceros iniciales</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
               <TextField
               type="number"
                  id="fill_row"
                  label={""}
                  placeholder={"Cantidad de ceros"}
                  size="small"
                  multiline
                  variant="outlined"
                  value={
                    numberOfZeros === "" ? "" : numberOfZeros
                  }
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const regex = /^[0-9\b]+$/;
                    if (
                      event.target.value === "" ||
                      regex.test(event.target.value)
                    ) {
                      setNumberOfZeros(event.target.value);
                    }
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "var(--magenta)",
                    },
                  }}
                  disabled={!disabledFillWithZeros}
                  error={numberOfZeros === "" ? true : false}
                  sx={{ marginRight: "10px" }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label=""
              checked={disabledReplaceInput}
              onChange={handleChangedisabledReplaceInput}
            />
            <Stack
              sx={{
                width: "100%",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={form_label}>Reemplazar Valores</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    control={<Radio color="secondary" />}
                    label="Coincidencia Exacta"
                    value={"totalCellValue"}
                    sx={{
                      color: totalCellValue === "" ? "red" : "black",
                    }}
                    checked={totalCellValue === "totalCellValue"}
                    disabled={!disabledReplaceInput}
                    onChange={(e: any) => {
                      setTotalCellValue(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="secondary" />}
                    label="Coincidencia Parcial"
                    value={"cellContaining"}
                    sx={{
                      color: totalCellValue === "" ? "red" : "black",
                    }}
                    checked={totalCellValue === "cellContaining"}
                    disabled={!disabledReplaceInput}
                    onChange={(e: any) => {
                      setTotalCellValue(e.target.value);
                    }}
                  />
                </RadioGroup>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                }}
              >
                <TextField
                  type="number"
                  id="top_n_rows"
                  label={""}
                  placeholder={"Valor que buscar"}
                  size="small"
                  multiline
                  variant="outlined"
                  value={
                    inputValueToBeReplaced === "" ? "" : inputValueToBeReplaced
                  }
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInputValueToBeReplaced(event.target.value);
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "var(--magenta)",
                    },
                  }}
                  disabled={!disabledReplaceInput}
                  error={inputValueToBeReplaced === "" ? true : false}
                  sx={{ marginRight: "10px" }}
                />
                <Select
                  isClearable
                  className="cc_select"
                  onChange={replaceWithChangeHandler}
                  reference={replaceWithRef}
                  styles={selectStyles(replaceWithSelectedOption)}
                  options={replaceWithOptions}
                  closeMenuOnSelect={true}
                  placeholder="Tipo de reemplazo"
                  isDisabled={!disabledReplaceInput}
                  defaultValue={
                    dataItem
                      ? dataItem.detalle_item.tipo_modificacion?.find((item: any) =>
                          item.value.startsWith("reemplazar")
                        )
                        ? dataItem.detalle_item.tipo_modificacion?.find((item: any) =>
                            item.value.startsWith("reemplazar")
                          )?.tipo === "nulo"
                          ? replaceWithOptions[1]
                          : replaceWithOptions[0]
                        : undefined
                      : undefined
                  }
                />
                {replaceWithSelectedOption?.value === "valor" && (
                  <TextField
                    type="number"
                    id="top_n_rows"
                    label={""}
                    placeholder={"Reemplazar con..."}
                    size="small"
                    multiline
                    variant="outlined"
                    value={
                      inputValueWouldReplace === ""
                        ? ""
                        : inputValueWouldReplace
                    }
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setInputValueWouldReplace(event.target.value);
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    disabled={!disabledReplaceInput}
                    error={inputValueWouldReplace === "" ? true : false}
                    sx={{ marginLeft: "10px" }}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label=""
              checked={disabledModifyType}
              onChange={handleChangeDisabledModifyType}
            />
            <Stack
              sx={{
                width: "100%",
              }}
            >
              <Typography sx={form_label}>Modificar Tipo</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Select
                  id="demo-multiple-chip"
                  isClearable
                  className="cc_select"
                  onChange={typeChangeHandler}
                  reference={typeSelectedRef}
                  styles={selectStyles(
                    typeSelectedOption,
                    disabledModifyType === true &&
                      typeSelectedOption?.value === "fecha"
                      ? "365px"
                      : "100%"
                  )}
                  options={typeOptions}
                  name="demo-multiple"
                  closeMenuOnSelect={true}
                  placeholder="Elegir tipo..."
                  isDisabled={!disabledModifyType}
                  defaultValue={
                    dataItem
                      ? dataItem.detalle_item.tipo_modificacion?.find(
                          (item: any) => item.value === "cambiar_tipo_dato"
                        )
                        ? {
                            value: dataItem.detalle_item.tipo_modificacion?.find(
                              (item: any) => item.value === "cambiar_tipo_dato"
                            ).label,
                            label:
                              TYPE_OPTIONS[
                                dataItem.detalle_item.tipo_modificacion?.find(
                                  (item: any) =>
                                    item.value === "cambiar_tipo_dato"
                                ).label
                              ],
                          }
                        : undefined
                      : undefined
                  }
                />

                {disabledModifyType === true &&
                  typeSelectedOption?.value === "fecha" && (
                    <Select
                      id="demo-multiple-chip"
                      isClearable
                      className="cc_select"
                      onChange={formatDateChangeHandler}
                      reference={formatDateSelectedRef}
                      styles={selectStyles(formatDateSelectedOption, "365px")}
                      options={typeFormatDate}
                      name="demo-multiple"
                      closeMenuOnSelect={true}
                      placeholder="Elegir tipo..."
                      isDisabled={!disabledModifyType}
                      defaultValue={
                        dataItem?.detalle_item.tipo_modificacion?.find(
                          (item: any) => item.value === "cambiar_tipo_dato"
                        )
                          ? {
                              value: dataItem?.detalle_item?.tipo_modificacion?.find(
                                (item: any) =>
                                  item.value === "cambiar_tipo_dato"
                              ).label_2,
                              label: dataItem?.detalle_item.tipo_modificacion?.find(
                                (item: any) =>
                                  item.value === "cambiar_tipo_dato"
                              ).label_2,
                            }
                          : undefined
                      }
                    />
                  )}
              </Stack>
            </Stack>
          </Stack>
          <FormGroup
            sx={{
              flexDirection: "row",
              alignItems: "flex-end",
              color:
                typeSelectedOption?.value === "decimal" ||
                typeSelectedOption?.value === "entero" ||
                typeSelectedOption?.value === "fecha" ||
                disabledNumericValue
                  ? "gray"
                  : "black",
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label=""
              checked={disabledMayusMinus}
              onChange={handleChangeDisabledMayusMinus}
              disabled={
                typeSelectedOption?.value === "decimal" ||
                typeSelectedOption?.value === "entero" ||
                typeSelectedOption?.value === "fecha" ||
                disabledNumericValue
                  ? true
                  : false
              }
            />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              sx={{ width: "90%", flexDirection: "row" }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  color: "var(--blue)",
                  fontSize: "var(--text)",
                }}
              >
                Transformación de Texto
              </Typography>
              <FormControlLabel
                sx={{
                  width: "45%",
                  color: mayusMinusValue === "" ? "red" : "black",
                }}
                control={<Radio color="secondary" />}
                label="Convertir a Mayúsculas"
                value={"mayuscula"}
                checked={mayusMinusValue === "mayuscula"}
                disabled={!disabledMayusMinus}
                onChange={(e: any) => {
                  setMayusMinusValue(e.target.value);
                }}
              />
              <FormControlLabel
                sx={{
                  width: "45%",
                  color: mayusMinusValue === "" ? "red" : "black",
                }}
                control={<Radio color="secondary" />}
                label="Convertir a Minúsculas"
                value={"minuscula"}
                checked={mayusMinusValue === "minuscula"}
                disabled={!disabledMayusMinus}
                onChange={(e: any) => setMayusMinusValue(e.target.value)}
              />
            </RadioGroup>
          </FormGroup>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              color:
                typeSelectedOption?.value === "texto" ||
                typeSelectedOption?.value === "fecha" ||
                disabledMayusMinus
                  ? "gray"
                  : "black",
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label=""
              checked={disabledNumericValue}
              onChange={handleChangeDisabledNumericValue}
              disabled={
                typeSelectedOption?.value === "texto" ||
                typeSelectedOption?.value === "fecha" ||
                disabledMayusMinus
                  ? true
                  : false
              }
            />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              sx={{ width: "90%", flexDirection: "row" }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  color: "var(--blue)",
                  fontSize: "var(--text)",
                }}
              >
                Transformación de Números
              </Typography>
              <FormControlLabel
                sx={{
                  width: "45%",
                  color: numericValue === "" ? "red" : "black",
                }}
                control={<Radio color="secondary" />}
                value="abs"
                label="Convertir a valor absoluto"
                checked={numericValue === "abs"}
                disabled={!disabledNumericValue}
                onChange={(e: any) => setNumericValue(e.target.value)}
              />
              <FormControlLabel
                sx={{
                  width: "45%",
                  color: numericValue === "" ? "red" : "black",
                }}
                value="negativos_a_cero"
                checked={numericValue === "negativos_a_cero"}
                control={<Radio color="secondary" />}
                label="Convertir valores negativos a cero"
                disabled={!disabledNumericValue}
                onChange={(e: any) => setNumericValue(e.target.value)}
              />
            </RadioGroup>
          </FormGroup>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label=""
              checked={removeSpaces}
              onChange={handleChangeRemoveSpaces}
            />
            <Stack
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  color: "var(--blue)",
                  fontSize: "var(--text)",
                }}
              >
                Limpieza de espacios
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  color: !removeSpaces ? "gray" : "black",
                  pt: 1,
                  pb: 1,
                }}
              >
                Eliminar espacios iniciales y finales
              </Typography>
            </Stack>
          </Stack>
        </FormControl>}
      
      </Stack>
    </BaseModal>
  );
};

export default ModifyColumn;
