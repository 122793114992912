import { useCallback, useEffect, useRef, useState } from "react";
import {
  Background,
  BackgroundVariant,
  Controls,
  EdgeChange,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  ReactFlow,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import {
  DELETE_NODES_CONNECTION,
  FLOW_GROUP,
  FLOW_GROUPS,
  FLOW_ITEMS,
  FLOW_URL,
  GET_FLOW_URL,
  ORDER_FLOW,
  PUT_NAME_FLOW_URL,
  RECALCULATE_FONTS,
} from "../../../api/axios";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts";
import {
  Box,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PinButton from "../FlowStepper/PinButton";
import {
  canva_container_styles,
  canva_content_container_name,
  canva_content_container_styles,
  canva_title_container_styles,
  form_label_name,
  typographyh5_canva_styles,
} from "../../../styles/app-styles";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CachedIcon from "@mui/icons-material/Cached";
import DoneIcon from "@mui/icons-material/Done";
import FontNode from "./nodes/FontNode";
import { useStore } from "./store/store";
import NewColumnNode from "./nodes/NewColumnNode";
import DeleteColumnNode from "./nodes/DeleteColumnNode";
import ModifyColumnsNode from "./nodes/ModifyColumnsNode";
import MergeNode from "./nodes/MergeNode";
import GroupByNode from "./nodes/GroupByNode";
import ConcatNode from "./nodes/ConcatNode";
import FilterNode from "./nodes/FilterNode";
import RenameColumnNode from "./nodes/RenameColumnsNode";
import DesdynamizeColumnsNode from "./nodes/DesdynamizeColumnsNode";
import SetHeaderNode from "./nodes/SetHeaderNode";
import DynamizeColumnsNode from "./nodes/DynamizeColumnsNode";
import CalculateNode from "./nodes/CalculateNode";
import DeleteRowsNode from "./nodes/DeleteRowsNode";
import { getBackendId, getNode } from "../../../utils/util";
import shallow from "zustand/shallow";
import {
  areAllSelectedNodesConnected,
  checkConcatConfigAndDeleteOldParents,
  checkIfNodeHasPendingEdges,
  findItemById,
  findItemByParentId,
  getAllDescendants,
  getParentIdsPutConnection,
  setErrorFlowNodes,
  setInitialFlowEdges,
  setInitialFlowNodes,
  setItemsColor,
  setLocationFlowNodes,
  validateFlowName,
} from "../utils";
import CustomEdge from "./edges/CustomEdge";
import GroupNode from "./nodes/GroupNode";
import { ConfirmationModal } from "../../dialog";
import useDialog from "../../../hooks/useDialog";
import SaveNameModal from "../../dialog/SaveNameModal";
import CustomWarning from "../../warnings/CustomWarning";
import SelectColumnsNode from "./nodes/SelectColumnsNode";

interface FlowDiagramProps {
  isLoadingFlow: boolean;
}

const nodeTypes = {
  font: FontNode,
  "new-column": NewColumnNode,
  "delete-column": DeleteColumnNode,
  "delete-rows": DeleteRowsNode,
  "modify-column": ModifyColumnsNode,
  merge: MergeNode,
  "group-by": GroupByNode,
  concat: ConcatNode,
  filter: FilterNode,
  "rename-columns": RenameColumnNode,
  dinamize: DynamizeColumnsNode,
  desdinamize: DesdynamizeColumnsNode,
  "set-header": SetHeaderNode,
  calculate: CalculateNode,
  "node-group": GroupNode,
  "select-columns": SelectColumnsNode
};

const edgeTypes = {
  "custom-edge": CustomEdge,
};

const selector = (store: any) => ({
  nodes: store.nodes,
  edges: store.edges,
  flow: store.flow,
  onNodesChange: store.onNodesChange,
  onEdgesChange: store.onEdgesChange,
  addEdge: store.addEdge,
  createNode: store.createNode,
  saveFlow: store.saveFlow,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  onNodesDelete: store.onNodesDelete,
  selectedNodes: store.selectedNodes,
  updateNode: store.updateNode,
  updateNodePosition: store.updateNodePosition,
  currentColors: store.currentColors,
  reset: store.reset,
  isFlowLoaded: store.isFlowLoaded,
  selectedEdges: store.selectedEdges,
  updateSelectedEdges: store.updateSelectedEdges,
});

const FlowDiagram = ({ isLoadingFlow }: FlowDiagramProps) => {
  const [flowName, setFlowName] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const [openInputChangeName, setOpenInputChangeName] =
    useState<boolean>(false);
  const [openDeleteConfirmationModal, handleToggleDeleteConfirmationModal] =
    useDialog();
  const [
    openMoreThanOneGroupSelectedWarningModal,
    handleToggleMoreThanOneGroupSelecteWarningdModal,
  ] = useDialog();
  const [
    openNodesAreNotConfiguredWarningModal,
    handleToggleNodesAreNotConfiguredWarningModal,
  ] = useDialog();
  const [
    openNodesAreNotConnectedWarningModal,
    handleToggleNodesAreNotConnectedWarningModal,
  ] = useDialog();
  const [openWarningDeleteGroupsModal, handleToggleDeleteGroupWarningModal] =
    useDialog();
  const [openGroupModal, toggleOpenGroupModal] = useDialog();
  const [elementsToRemove, setElementsToRemove] = useState<any>([]);
  const [resolvePromise, setResolvePromise] = useState<any>(null);
  const [concatInfo, setConcatInfo] = useState<any>();
  const [concatInfoBackend, setConcatInfoBackend] = useState<any>();

  /******************************* REACT FLOW *************************************** */

  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  const flowRef = useRef<any>();
  nodesRef.current = store.nodes;
  flowRef.current = store.flow;

  console.log(store);

  const { fitView } = useReactFlow();
  const onNodesDelete = useCallback(
    (deleted: any) => {
      store.onEdgesChange(
        deleted.reduce((acc: any, node: any) => {
          const incomers = getIncomers(node, store.nodes, store.edges);
          const outgoers = getOutgoers(node, store.nodes, store.edges);
          const connectedEdges = getConnectedEdges([node], store.edges);

          const remainingEdges = acc.filter(
            (edge: any) =>
              !connectedEdges.map((edge: any) => edge.id).includes(edge.id)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              type: "add",
              item: {
                id: `${source}->${target}`,
                source,
                target,
              },
            }))
          );
          createdEdges.forEach((edge: any) => store.addEdge(edge.item));
          return [...remainingEdges /*...createdEdges*/];
        }, store.edges)
      );
    },
    [store.nodes, store.edges]
  );

  const onNodesConnect = (data: any) => {
    const node = getNode(data.target, store.nodes);
    const sourceNode = getNode(data.source, store.nodes);

    node.type !== "concat" && store.addEdge(data);
    if (sourceNode.type === "node-group") {
      if (parseInt(getBackendId(data.target, store.nodes))) {
        putConnection(undefined, [
          {
            id: parseInt(getBackendId(data.target, store.nodes)),
            parent_ids: [
              parseInt(
                getBackendId(
                  sourceNode.data.nodes[sourceNode.data.nodes.length - 1].id,
                  store.nodes
                )
              ),
            ],
            cambiar_conexiones: true,
            editar_item_general: false,
            editar_detalle_item: false,
            calcular: true,
          },
        ]);
      }
      store.addEdge({
        source: sourceNode.data.nodes[sourceNode.data.nodes.length - 1].id,
        sourceHandle: `handle-source-2-${
          sourceNode.data.nodes[sourceNode.data.nodes.length - 1].id
        } `,
        target: data.target,
        targetHandle: `handle-target-2-${data.target} `,
      });
      return;
    }
    if (node.data?.backendId) {
      if (node.type !== "merge" && node.type !== "concat") {
        // Si el item a conectar tiene backendId hacemos el llamado si no no
        if (
          parseInt(getBackendId(data.source, store.nodes)) &&
          node.type !== "concat"
        ) {
          putConnection(undefined, [
            {
              id: parseInt(getBackendId(data.target, store.nodes)),
              parent_ids: [parseInt(getBackendId(data.source, store.nodes))],
              cambiar_conexiones: true,
              editar_item_general: false,
              editar_detalle_item: false,
              calcular: true,
            },
          ]);
        } else {
          const pendingEdges =
            localStorage.getItem("pendingEdges") !== null
              ? JSON.parse(localStorage.getItem("pendingEdges") as string)
              : [];
          localStorage.setItem(
            "pendingEdges",
            JSON.stringify([
              ...pendingEdges,
              {
                nodeId: data.source,
                call: {
                  id: parseInt(getBackendId(data.target, store.nodes)),
                  cambiar_conexiones: true,
                  editar_item_general: false,
                  editar_detalle_item: false,
                  calcular: true,
                },
              },
            ])
          );
        }
      } else {
        if (
          node.data.parentIds
            .map((parentId: string) =>
              parseInt(getBackendId(parentId, store.nodes))
            )
            .every((id: any) => !isNaN(id)) &&
          node.type !== "concat"
        ) {
          putConnection(undefined, [
            {
              id: parseInt(getBackendId(data.target, store.nodes)),
              parent_ids: node.data.parentIds.map((parentId: string) =>
                parseInt(getBackendId(parentId, store.nodes))
              ),
              cambiar_conexiones: true,
              editar_item_general: false,
              editar_detalle_item: false,
              calcular: true,
            },
          ]);
        }
      }
    }
    if (node?.data?.parentIds?.length < 2) {
      store.updateNodePosition(node.id, {
        x: node.position.x,
        y: sourceNode.position.y,
      });
    }
    if (node.type !== "merge" && node.type !== "concat") {
      store.updateNode(node.id, {
        ...node.data,
        color: sourceNode.data.color,
      });
    }
    if (node.type === "concat") {
      const connections: any = [];
      const selectedNodes = store.nodes.filter(
        (node: any) => node.selected && node.id !== data.target
      );
      selectedNodes.forEach((node: any) => {
        store.addEdge({
          source: node.id.toString(),
          sourceHandle: `handle-source-2-${node.id.toString()}`,
          target: data.target,
          targetHandle: data.targetHandle,
        });
        if (parseInt(getBackendId(node.id, store.nodes))) {
          connections.push(node);
        } else {
          const pendingEdges =
            localStorage.getItem("pendingEdges") !== null
              ? JSON.parse(localStorage.getItem("pendingEdges") as string)
              : [];
          localStorage.setItem(
            "pendingEdges",
            JSON.stringify([
              ...pendingEdges,
              {
                nodeId: node.id,
                call: {
                  id: parseInt(getBackendId(data.target, store.nodes)),
                  cambiar_conexiones: true,
                  editar_item_general: false,
                  editar_detalle_item: false,
                  calcular: true,
                },
              },
            ])
          );
        }
      });
      if (selectedNodes.length === 0) {
        store.addEdge(data);
      }
      if (
        parseInt(
          getBackendId(data.source, store.nodes) &&
            parseInt(getBackendId(data.target, store.nodes))
        )
      ) {
        putConnection(undefined, [
          {
            id: parseInt(getBackendId(data.target, store.nodes)),
            parent_ids: getParentIdsPutConnection(
              node.data?.parentIds,
              connections,
              store.nodes
            ),
            cambiar_conexiones: true,
            editar_item_general: false,
            editar_detalle_item: false,
            calcular: true,
          },
        ]);
      } else {
        const pendingEdges =
          localStorage.getItem("pendingEdges") !== null
            ? JSON.parse(localStorage.getItem("pendingEdges") as string)
            : [];
        localStorage.setItem(
          "pendingEdges",
          JSON.stringify([
            ...pendingEdges,
            {
              nodeId: data.source,
              call: {
                id: parseInt(getBackendId(data.target, store.nodes)),
                cambiar_conexiones: true,
                editar_item_general: false,
                editar_detalle_item: false,
                calcular: true,
              },
            },
          ])
        );
      }

      if (
        !node.data.backendId &&
        node.data.parentIds?.length > 1 &&
        parseInt(getBackendId(data.source, store.nodes))
      ) {
        setConcatInfo({
          id: node.id,
          name: "",
          stepsToConcatSelectedOption: node.data.parentIds.map((node: any) =>
            parseInt(getBackendId(node, store.nodes))
          ),
          parentBackendIds: node.data.parentIds.map((node: any) =>
            parseInt(getBackendId(node, store.nodes))
          ),
        });
        const completedInfo = {
          tipo: "concat",
          calcular: true,
          editar_detalle_item: true,
          cambiar_conexiones: true,
          parent_ids: node.data.parentIds.map((node: any) =>
            parseInt(getBackendId(node, store.nodes))
          ),
          ...node.data.position,
          comentario: "",
        };
        postConcat(undefined, completedInfo);
      }
    }
  };

  /******************************* LLAMADAS *********************************************/

  const onSuccessPostFlowName = (data: any) => {
    setOpenInputChangeName(false);
    store.saveFlow(data.id, flowName);
    localStorage.setItem("flowId", data.id);
  };

  const { isLoading: isLoadingPostFlowName, callApi: postFlowName } = useApi(
    FLOW_URL,
    "POST",
    texts.flows.postFlowName.codes,
    { nombre: flowName },
    onSuccessPostFlowName
  );

  const { isLoading: isLoadingPostNewFlowName, callApi: putNewFlowName } =
    useApi(
      PUT_NAME_FLOW_URL(store.flow?.id),
      "PUT",
      texts.flows.postFlowName.codes,
      { nombre: flowName },
      onSuccessPostFlowName
    );

  const { data: dataFlows, callApi: getDataFlows } = useApi(
    FLOW_URL,
    "GET",
    texts.flows.getFlows.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessGetFlow = (data: any) => {
    store.saveFlow(data.id, data.nombre);
  };

  const {
    isLoading: isLoadingGetFlow,
    data: dataFlow,
    callApi: getDataFlow,
  } = useApi(
    GET_FLOW_URL(store.flow.id),
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  const {
    isLoading: isLoadingGetFlowForOrdering,
    data: dataFlowForOrdering,
    callApi: getDataFlowOrdering,
  } = useApi(
    GET_FLOW_URL(store.flow.id),
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const { isLoading: isLoadingRecalculateFonts, callApi: recalculateFonts } =
    useApi(
      RECALCULATE_FONTS(store.flow.id),
      "PUT",
      texts.flows.getFlow.codes,
      undefined,
      () => {
        getDataFlow();
      },
      undefined,
      false
    );

  // Este use Effect es para que cuando se actualize la data del flow del back se actualice que items tienen error
  useEffect(() => {
    if (dataFlow?.items?.length > 0) {
      setTimeout(() => {
        store.setInitialNodes(setErrorFlowNodes(store.nodes, dataFlow?.items));
      }, 2000);
    }
  }, [dataFlow]);

  // Este use Effect es para que cuando se actualize la data del flow del back se ordenen los items
  useEffect(() => {
    if (dataFlowForOrdering?.items?.length > 0) {
      setTimeout(() => {
        store.setInitialNodes(
          setLocationFlowNodes(store.nodes, dataFlowForOrdering?.items)
        );
      }, 2000);
    }
  }, [dataFlowForOrdering]);

  useEffect(() => {
    if (concatInfo?.id) {
      store.updateNode(concatInfo?.id, { ...concatInfo, ...concatInfoBackend });
      if (concatInfoBackend?.backendId) {
        checkIfNodeHasPendingEdges(
          concatInfo?.id,
          putConnection,
          concatInfoBackend?.backendId
        );
      }
    }
  }, [concatInfo, concatInfoBackend]);

  useEffect(() => {
    setItemsColor(store.nodes, store.updateNode, store.edges);
  }, [store.isFlowLoaded]);

  useEffect(() => {
    getDataFlows();
    // Efecto de limpieza
    const saveNodesLocation = () => {
      const nodesFormatted = nodesRef.current
        .filter((node) => node.data.backendId !== undefined || node.data.groupId!== undefined)
        .map((node: any) => {
          return node.type === "node-group"
            ? {
                id: parseInt(node.data.groupId),
                grupo: true,
                x: node.position.x,
                y: node.position.y,
              }
            : {
                id: parseInt(node.data.backendId),
                x: node.position.x,
                y: node.position.y,
                calcular: false,
                cambiar_conexiones: false,
                editar_item_general: true,
                editar_detalle_item: false,
              };
        });
      if (flowRef.current.id && nodesFormatted.length > 0) {
        saveFlow(FLOW_ITEMS(flowRef.current.id), nodesFormatted);
      }
      store.reset();
    };
    window.addEventListener("beforeunload", saveNodesLocation);
    return () => {
      window.removeEventListener("beforeunload", saveNodesLocation);
      saveNodesLocation();
    };
  }, []);

  const onSuccessChangeItems = () => {
    getDataFlow(GET_FLOW_URL(parseInt(store.flow.id)));
  };

  const { isLoading: isLoadingDeleteNodes, callApi: deleteNodes } = useApi(
    FLOW_ITEMS(store.flow.id),
    "DELETE",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessChangeItems,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessChangeItems,
    undefined,
    false
  );

  const { callApi: saveFlow } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessOrderFlow = () => {
    getDataFlowOrdering();
  };

  const { callApi: callOrderFlow, isLoading: isLoadingOrderFlow } = useApi(
    ORDER_FLOW(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessOrderFlow,
    undefined,
    false
  );

  const onSuccessCreateGroup = (data: any) => {
    const selectedNodes = store.nodes
      .filter(
        (node: any) =>
          node.selected && node.data.backendId && node.type !== "node-group"
      ) // Lo ordenamos por la posicion que tienen en el flujo
      ?.sort((a: any, b: any) => a.position.x - b.position.x);
    const groupNodeSelected = store.nodes
      .filter((node: any) => node.selected)
      .find((node: any) => node.type === "node-group");
    if (groupNodeSelected) {
      store.setInitialNodes(
        nodesRef.current.filter(
          (node: any) =>
            !selectedNodes.map((node: any) => node.id).includes(node.id)
        )
      );
      store.updateNode(groupNodeSelected.id, {
        nodes: [...groupNodeSelected.data.nodes, ...selectedNodes],
        backendId: groupNodeSelected.id,
      });
    } else {
      const selectedNodesIds = selectedNodes.map(
        (node: any) => node.data.backendId
      );
      store.setInitialNodes(
        nodesRef.current.filter(
          (node: any) => !selectedNodesIds.includes(node.data.backendId)
        )
      );
      const lastNodeConnection = findItemByParentId(
        selectedNodes[selectedNodes.length - 1].id,
        store.nodes
      );
      const firstNodeConnection = findItemById(
        selectedNodes[0].data.parentIds[0],
        store.nodes
      );
      store.createNode(
        "node-group",
        {
          nodes: selectedNodes,
          groupId: data.id,
          nombre: groupName,
          parentIds: [selectedNodes[0].parentIds],
          color: selectedNodes[0].data.color,
          backendId: data.id,
        },
        selectedNodes[0].position,
        // Este param se manda solo cuando el primero de los nodos del grupo creado estaba unido a otro nodo padre
        firstNodeConnection !== null ? firstNodeConnection.id : undefined,
        // Este param se manda solo cuando el ultimo de los nodos del grupo creado estaba unido a otro nodo
        lastNodeConnection !== null ? lastNodeConnection.id : undefined
      );
      console.log(lastNodeConnection, "last ");
    }
    setGroupName("");
  };

  const { callApi: createGroup, isLoading: isLoadingCreateGroup } = useApi(
    FLOW_GROUPS(store.flow.id),
    "POST",
    texts.flows.createGroup.codes,
    undefined,
    onSuccessCreateGroup,
    undefined,
    false
  );

  const { callApi: editGroup, isLoading: isLoadingEditGroup } = useApi(
    FLOW_GROUP,
    "PUT",
    texts.flows.editGroup.codes,
    undefined,
    onSuccessCreateGroup,
    undefined,
    false
  );

  const { callApi: deleteConnection, isLoading: isLoadingDeleteConnection } =
    useApi(
      DELETE_NODES_CONNECTION,
      "PUT",
      texts.flows.deleteConnection.codes,
      undefined,
      () => {
        getDataFlow();
      },
      undefined,
      false
    );

  const onSuccessGetFlowConcat = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current, data.items));
  };

  const { isLoading: isLoadingFlowConcat, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlowConcat,
    undefined,
    false
  );

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    setConcatInfoBackend({
      backendId: data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
    }),
      getFlow(GET_FLOW_URL(parseInt(store.flow.id)));
  };

  const {
    isLoading: isLoadingPostConcat,
    callApi: postConcat,
    error: errorPostConcat,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  /******************************* HANDLERS *********************************************/

  const isNameDuplicated = () => {
    return (
      !!dataFlows &&
      !!dataFlows?.find(
        (flow: any) =>
          flow.nombre.toLowerCase().trim() === flowName.toLowerCase().trim()
      )
    );
  };

  const handleChangeInputFlowName = () => {
    setOpenInputChangeName(!openInputChangeName);
  };

  const handleRecalculateFonts = () => {
    recalculateFonts(
      undefined,
      store?.nodes
        ?.filter((node: any) => node.type === "font" && node.data.backendId)
        ?.map((node: any) => node.data.backendId)
    );
  };

  const handleResetZoom = () => {
    // Establecer los valores predeterminados para la vista (por ejemplo, zoom = 1 y posición (0, 0))
    fitView();
  };

  const handleGroup = () => {
    if (openGroupModal) {
      toggleOpenGroupModal();
    }
    const selectedNodes = store.nodes
      .filter(
        (node: any) =>
          node.selected && node.data?.backendId && node.type !== "node-group"
      )
      .sort((a: any, b: any) => a.position.x - b.position.x);
    const groupNodeSelected = store.nodes
      .filter((node: any) => node.selected)
      .find((node: any) => node.type === "node-group");

    if (groupNodeSelected) {
      selectedNodes.length > 0 &&
        editGroup(FLOW_GROUP(store?.flow?.id, groupNodeSelected.data.groupId), {
          items: [
            ...groupNodeSelected.data.nodes.map((node: any) =>
              node.backendId ? node.backendId : node.data?.backendId
            ),
            ...selectedNodes.map((node: any) => node.data?.backendId),
          ],
        });
    } else {
      createGroup(undefined, {
        nombre: groupName,
        items: selectedNodes.map((node: any) => node.data?.backendId),
      });
    }
  };

  const handleDeleteNodes = (deleted: any) => {
    onNodesDelete(deleted);
    deleteNodes(
      undefined,
      deleted
        .filter((node: any) => node.data.backendId !== undefined)
        .map((node: any) => {
          return {
            id: node.data.backendId,
            // Cuando el item tiene solo una conexion y se elimina se crea una conexion entre su padre y su hijo
            asignar_abuelo:
              /*node.data.parentIds.length > 0 && */ node.type !== "merge" &&
              node.type !== "concat",
          };
        })
    );
  };

  const onNodeClick = (event: any, node: any) => {
    /* if (node.type === 'font') {
      const allConnectedNodes = getAllDescendants(node.id, store.nodes);
     allConnectedNodes.forEach((node: any)=>{
      store.updateSelectionNode(node.id, true)
     })
    }*/
  };

  const handleDragNode = (data: any, node: any) => {
    if (node.type !== "merge" && node.type !== "concat") {
      // Comentado por pedido de JC
      /* if (node?.data?.parentIds?.length > 0) {
        const sourceNode = getNode(node.data.parentIds[0], store.nodes);
        store.updateNodePosition(node.id, {
          x: sourceNode.position.x + 60,
          y: sourceNode.position.y,
        });
      }*/
    }
  };
  /************************* DELETE NODES ************************* */
  const onBeforeDelete = useCallback((elementsToDelete: any) => {
    if (elementsToDelete.nodes.length === 0) {
      return Promise.resolve(true);
    } else {
      return new Promise((resolve) => {
        setElementsToRemove(elementsToDelete);
        if (
          elementsToDelete.nodes?.some((node: any) => {
            return node.type === "node-group";
          })
        ) {
          handleToggleDeleteGroupWarningModal();
          return;
        }
        handleToggleDeleteConfirmationModal();
        setResolvePromise(() => resolve); /// Resolvemos la promesa con false para interrumpir la eliminación
      }) as Promise<boolean>;
    }
  }, []);

  const handleAcceptDelete = () => {
    handleToggleDeleteConfirmationModal();
    resolvePromise(true);
    handleDeleteNodes(elementsToRemove.nodes);
  };

  const handleCancelDelete = () => {
    handleToggleDeleteConfirmationModal(); // Solo cierra el modal
    resolvePromise(false); // Resuelve la promesa con false
  };

  const handleClickOnGroup = () => {
    const groupNodeSelected = store.nodes
      .filter((node: any) => node.selected)
      .find((node: any) => node.type === "node-group");
    const areMoreThanOneGroupNodeSelected =
      store.nodes
        .filter((node: any) => node.selected)
        .filter((node: any) => node.type === "node-group").length > 1;

    const areItemsWithoutBackendId = store.nodes
      .filter((node: any) => node.selected)
      .some((node: any) => !node.data?.backendId && node.type !== "node-group");

    const areAllNodesConnected = areAllSelectedNodesConnected(
      store.nodes.filter((node: any) => node.selected),
      store.edges
    );
    if (areMoreThanOneGroupNodeSelected) {
      handleToggleMoreThanOneGroupSelecteWarningdModal();
      return;
    }

    if (areItemsWithoutBackendId) {
      handleToggleNodesAreNotConfiguredWarningModal();
      return;
    }
    if (!areAllNodesConnected) {
      handleToggleNodesAreNotConnectedWarningModal();
      return;
    }

    if (groupNodeSelected) {
      handleGroup();
    } else {
      toggleOpenGroupModal();
    }
  };

  const handleChangeConnections = (changes: EdgeChange[]) => {
    if (changes[0]?.type === "remove") {
      const groupedChanges: any = [];

      changes.forEach((change: any) => {
        const edge = store.edges.find((edge: any) => {
          return edge.id === change.id;
        });
        const edgeTargetNode = store.nodes.find((node: any) => {
          return node.id === edge.target;
        });

        const edgeSourceNode = store.nodes.find((node: any) => {
          return node.id === edge.source;
        });
        if (edgeSourceNode?.type === "node-group") {
          store.updateNode(edgeTargetNode.id, {
            ...edgeTargetNode.data,
            parentIds: edgeTargetNode.data.parentIds?.filter(
              (parentId: string) =>
                parentId !=
                edgeSourceNode?.data?.nodes[
                  edgeSourceNode.data.nodes.length - 1
                ]?.id
            ),
          });
          if (getBackendId(edgeTargetNode.id, store.nodes)) {
            deleteConnection(
              DELETE_NODES_CONNECTION(
                store?.flow?.id,
                getBackendId(edgeTargetNode.id, store.nodes)
              ),
              {
                parent_ids: [
                  parseInt(
                    getBackendId(
                      edgeSourceNode?.data?.nodes[
                        edgeSourceNode.data.nodes.length - 1
                      ]?.id,
                      store.nodes
                    )
                  ),
                ],
              }
            );
          }
        }
        if (edgeTargetNode.data.backendId) {
          const targetId = edgeTargetNode.id;

          // Busca si ya existe un grupo para este targetId
          let group = groupedChanges.find(
            (group: any) => group.targetId === targetId
          );

          if (!group) {
            // Si no existe, crea uno nuevo
            group = { targetId, changes: [] };
            groupedChanges.push(group);
          }

          // Agrega el cambio al grupo correspondiente
          group.changes.push(change);
        }

        if (
          edgeTargetNode.type === "merge" &&
          edgeTargetNode.data.parentIds?.length === 1 &&
          edgeTargetNode.data.backendId
        ) {
          deleteNodes(undefined, [
            {
              id: edgeTargetNode.data.backendId,
              asignar_abuelo: false,
            },
          ]);
          store.updateNode(
            edgeTargetNode.id,
            {
              color: edgeTargetNode.data.color,
              error: true,
              errorMessage:
                "Es necesario reconfigurar el merge luego de que todas las fuentes sean reemplazadas",
            },
            true
          );
          const isMergeConnected = store.edges.find(
            (edge: any) =>
              edge.source == edgeTargetNode.data.backendId ||
              edge.source == edgeTargetNode.id
          );
          if (isMergeConnected) {
            const pendingEdges =
              localStorage.getItem("pendingEdges") !== null
                ? JSON.parse(localStorage.getItem("pendingEdges") as string)
                : [];
            localStorage.setItem(
              "pendingEdges",
              JSON.stringify([
                ...pendingEdges,
                {
                  nodeId: edgeTargetNode.id,
                  call: {
                    id: parseInt(
                      getBackendId(isMergeConnected.target, store.nodes)
                    ),
                    cambiar_conexiones: true,
                    editar_item_general: false,
                    editar_detalle_item: false,
                    calcular: true,
                  },
                },
              ])
            );
          }
        }
      });
      setTimeout(() => {
        groupedChanges.forEach((change: any) => {
          if (
            nodesRef.current.find((node: any) => node.id == change.targetId)
          ) {
            deleteConnection(
              DELETE_NODES_CONNECTION(
                store?.flow?.id,
                getBackendId(change.targetId, store.nodes)
              ),
              {
                parent_ids: change.changes.map((change: any) => {
                  return parseInt(
                    getBackendId(
                      store.edges.find((edge: any) => {
                        return edge.id === change.id;
                      }).source,
                      store.nodes
                    )
                  );
                }),
              }
            );
          }
        });
      }, 1000);
    }

    store.onEdgesChange(changes);
  };

  return (
    <Stack sx={canva_container_styles(store.flow?.name === "" && "80vh")}>
      <SimpleBackdrop
        open={isLoadingPostFlowName}
        message={texts.flows.postFlowName.loading}
      />
      <SimpleBackdrop
        open={isLoadingPostNewFlowName}
        message={texts.flows.postFlowName.loading}
      />
      <SimpleBackdrop
        open={isLoadingRecalculateFonts}
        message={texts.flows.recalculateFonts.loading}
      />
      <SimpleBackdrop
        open={isLoadingGetFlowForOrdering || isLoadingOrderFlow}
        message={texts.flows.orderFlow.loading}
      />
      <SimpleBackdrop
        open={isLoadingDeleteConnection}
        message={texts.flows.deleteConnection.loading}
      />
      {!isLoadingFlow && (
        <Stack sx={canva_content_container_styles}>
          {store.flow.id === undefined ? (
            <Stack sx={canva_content_container_name}>
              {store.flow.name === "" ||
                (store.flow.name === undefined && (
                  <InputLabel sx={form_label_name}>
                    Ingrese el nombre del flujo
                  </InputLabel>
                ))}
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  error={isNameDuplicated() || !validateFlowName(flowName)}
                  helperText={
                    isNameDuplicated()
                      ? "Ya existe un flujo con ese nombre"
                      : !validateFlowName(flowName) &&
                        "No es posible utilizar barras en el nombre del flujo "
                  }
                  label="Nombre del flujo"
                  sx={{ width: "50%", mr: "20px", bgcolor: "white" }}
                  value={flowName}
                  onChange={(e) => setFlowName(e.target.value)}
                  placeholder="Nombre del flujo"
                  autoComplete="off"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "var(--blue)",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "var(--magenta)",
                      margin: 0,
                      backgroundColor: "var(--very-very-light-grey)",
                    },
                  }}
                />
                <PinButton
                  Icon={DoneIcon}
                  tooltipTitle={"Aceptar"}
                  size={"50px"}
                  backgroundColor={
                    isNameDuplicated() || !validateFlowName(flowName)
                      ? "var(--light-grey)"
                      : "linear-gradient(to bottom, var(--greeny), var(--blue-greeny))"
                  }
                  /* disabled={isNameDuplicated() || flowName === ""} */
                  onClick={
                    isNameDuplicated() || !validateFlowName(flowName)
                      ? () => <></>
                      : () => postFlowName()
                  }
                />
              </Stack>
            </Stack>
          ) : openInputChangeName ? (
            <Stack
              sx={{ ...canva_title_container_styles, alignItems: "flex-start" }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Modificar nombre del flujo"
                error={isNameDuplicated()}
                helperText={
                  isNameDuplicated() && "Ya existe un flujo con ese nombre"
                }
                sx={{ width: "50%", mr: "20px", bgcolor: "white" }}
                value={flowName}
                onChange={(e) => setFlowName(e.target.value)}
                placeholder={store.flow?.name}
                autoComplete="off"
                InputLabelProps={{
                  style: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    color: "var(--blue)",
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                    margin: 0,
                    backgroundColor: "var(--very-very-light-grey)",
                  },
                }}
              />
              <Box sx={{ display: "flex", gap: 1, mt: "11px" }}>
                <PinButton
                  Icon={CheckCircleRoundedIcon}
                  tooltipTitle={"Confirmar"}
                  size={"30px"}
                  onClick={
                    isNameDuplicated() ? () => <></> : () => putNewFlowName()
                  }
                  tooltipPlacement={"bottom"}
                  backgroundColor={
                    isNameDuplicated()
                      ? "var(--light-grey)"
                      : "var(--blue-greeny)"
                  }
                />
                <PinButton
                  Icon={CancelRoundedIcon}
                  tooltipTitle={"Cancelar"}
                  size={"30px"}
                  onClick={handleChangeInputFlowName}
                  tooltipPlacement={"bottom"}
                  backgroundColor={"var(--dark-grey)"}
                />
              </Box>
            </Stack>
          ) : (
            <>
              <Typography
                sx={{
                  fontWeight: "600",
                  color: "var(--blue)",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  textTransform: "uppercase",
                  width: "230px",
                  cursor: "pointer",
                }}
                onClick={handleRecalculateFonts}
              >
                Recalcular fuentes
                <CachedIcon sx={{ marginLeft: "3px", marginBottom: "2px" }} />
                <Tooltip title="Actualiza las muestras si es necesario porque hubo un cambio en el archivo original">
                  <InfoIcon
                    sx={{
                      color: "var(--blue)",
                      fontSize: "21px",
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginBottom: "4px",
                    }}
                  />
                </Tooltip>
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={typographyh5_canva_styles}
              >
                {store.flow?.name}
                <PinButton
                  Icon={EditIcon}
                  tooltipTitle={"Editar nombre"}
                  size={"30px"}
                  backgroundColor={"var(--very-light-blue)"}
                  onClick={handleChangeInputFlowName}
                />
              </Typography>
            </>
          )}
        </Stack>
      )}
      {/* {openInputChangeName &&
      dataFlows &&
      dataFlows?.find((flow: any) => flow.nombre === flowName) && (
        <Typography
          sx={{
            color: "var(--red)",
          }}
        >
          Ya existe un flujo con ese nombre
        </Typography>
      )} */}
      {store.flow?.id !== "" && store.flow?.id !== undefined && (
        <Stack
          sx={{
            width: "85vw",
            height: "90vh",
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <ReactFlow
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodes={store.nodes}
            edges={store.edges}
            onNodesChange={store.onNodesChange}
            onEdgesChange={handleChangeConnections}
            onNodeDragStop={handleDragNode}
            onNodeClick={onNodeClick}
            // selectedNodes={selectedElements}
            onBeforeDelete={onBeforeDelete}
            onNodesDelete={handleDeleteNodes}
            onConnect={onNodesConnect}
            fitView
            attributionPosition="top-right"
            selectionKeyCode="Shift"
          >
            <button
              onClick={handleResetZoom}
              style={{
                width: "200px",
                zIndex: 100000,
                position: "absolute",
                top: 10,
                left: 10,
                padding: "5px 1px",
              }}
            >
              Restablecer zoom
            </button>
            <Tooltip title="En caso de seleccionar un item este se utilizará para el ordenamiento">
              <button
                onClick={() =>
                  callOrderFlow(
                    undefined,
                    store.nodes.filter((node: any) => node.selected)?.length > 0
                      ? {
                          item_id: getBackendId(
                            store.nodes.filter((node: any) => node.selected)[0]
                              .id,
                            store.nodes
                          ),
                        }
                      : undefined
                  )
                }
                style={{
                  width: "200px",
                  zIndex: 100000,
                  position: "absolute",
                  top: 10,
                  right: 10,
                  padding: "5px 1px",
                }}
              >
                Ordenar nodos
              </button>
            </Tooltip>
            <button
              onClick={handleClickOnGroup}
              disabled={
                store.nodes.filter((node: any) => node.selected).length === 0
              }
              style={{
                width: "200px",
                zIndex: 100000,
                position: "absolute",
                top: 10,
                right: 220,
                padding: "5px 1px",
              }}
            >
              Agrupar nodos
            </button>

            <Background
              gap={10}
              color="white"
              variant={BackgroundVariant.Cross}
            />
            <Controls />
          </ReactFlow>
          <ConfirmationModal
            open={openDeleteConfirmationModal}
            handleClose={handleCancelDelete}
            handleAccept={handleAcceptDelete}
            message="eliminar el nodo"
            title="Eliminar nodo"
          />
          <SaveNameModal
            open={openGroupModal}
            handleClose={toggleOpenGroupModal}
            handleAccept={handleGroup}
            title={"Nuevo grupo"}
            text={`Nombre del grupo`}
            label={"Nombre del grupo"}
            placeholder={"Nombre del archivo"}
            dispatchFunction={setGroupName}
            valueState={groupName}
            namesList={[]}
          />
          <CustomWarning
            open={openNodesAreNotConfiguredWarningModal}
            handleAccept={handleToggleNodesAreNotConfiguredWarningModal}
            title="No es posible agrupar los items"
            text="No es posible agrupar los items porque algunos de ellos aún no han sido configurados"
          />
          <CustomWarning
            open={openMoreThanOneGroupSelectedWarningModal}
            handleAccept={handleToggleMoreThanOneGroupSelecteWarningdModal}
            title="No es posible agrupar los items"
            text="No es posible agrupar los items porque hay más de un grupo seleccionado"
          />
          <CustomWarning
            open={openNodesAreNotConnectedWarningModal}
            handleAccept={handleToggleNodesAreNotConnectedWarningModal}
            title="No es posible agrupar los items"
            text="Para crear o editar una agrupación es necesario que todos los items estén conectados entre sí."
          />
          <CustomWarning
            open={openWarningDeleteGroupsModal}
            handleAccept={handleToggleDeleteGroupWarningModal}
            title="No es posible eliminar un grupo"
            text="Entre los elementos a eliminar hay un grupo seleccionado. No es posible eliminar grupos. Puedes desagruparlo."
          />
        </Stack>
      )}
    </Stack>
  );
};

export default FlowDiagram;
